<template>
  <div>
    <v-card-title>{{ `Manage Project - ${project.name}` }}</v-card-title>
    <v-expansion-panels v-model="panel">
      <v-expansion-panel>
        <v-expansion-panel-header>Details and Setup</v-expansion-panel-header>
        <v-expansion-panel-content>
          <!-- Project Details -->

          <div class="table-responsive">
            <table class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th style="width:30%" scope="col">Name</th>
                  <th :model="project.name" @blur="ev => updateProjectContent('name', ev.target.textContent)" :contenteditable="true">{{ project.name }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="bold">Status:</td>
                  <td>{{ statusMapping[project.status] }}</td>
                </tr>
                <tr>
                  <td class="bold">Survey Edit After Sync:</td>
                  <td :model="project.editAfterSync" @blur="ev => updateProjectContent('editAfterSync', ev.target.textContent)" :contenteditable="true">{{ project.editAfterSync }}</td>
                </tr>
                <tr>
                  <td class="bold">Project Id:</td>
                  <td>{{ project._id }}</td>
                </tr>
                <tr>
                  <td class="bold">Survey Id:</td>
                  <td :model="project.survey_id" @blur="ev => updateProjectContent('survey_id', ev.target.textContent)" :contenteditable="true">{{ project.survey_id }}</td>
                </tr>
                <tr>
                  <td class="bold">Created At:</td>
                  <td>{{ $moment(project.created_at).format("DD-MMMM-YYYY HH:mm:ss") }}</td>
                </tr>
                <tr>
                  <td class="bold">Updated At:</td>
                  <td>{{ $moment(project.updated_at).format("DD-MMMM-YYYY HH:mm:ss") }}</td>
                </tr>
                <tr v-if="project.created_by">
                  <td class="bold">Created By:</td>
                  <td>{{ project.created_by }}</td>
                </tr>
                <tr v-if="project.updated_by">
                  <td class="bold">Updated By:</td>
                  <td>{{ project.updated_by }}</td>
                </tr>
                <tr>
                  <td class="bold">Survey Tool:</td>
                  <td>{{ tools[project.survey_tool] ? tools[project.survey_tool] : project.survey_tool }}</td>
                </tr>
                <tr>
                  <td class="bold">Survey Url:</td>
                  <td>{{ project.survey_url }}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <v-select
            :items="label"
            name="valueLabel"
            item-text="name"
            item-value="_id"
            v-model="project.valueLabel"
            persistent-hint
            label="Value Label"
          ></v-select>

          <v-select
            v-model="project.key"
            :items="keys"
            name="decodedValue"
            item-text="name"
            persistent-hint
            label="Decoded Value"
          ></v-select>

          <v-checkbox
            label="Show Summary Tab"
            v-model="project.showSummaryTab"
            persistent-hint
            hint="Selecting this will add a text box under every chart."
          ></v-checkbox>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>Questions</v-expansion-panel-header>
        <v-expansion-panel-content>
          <!-- Json Editor -->
          <v-app-bar color="error" dark class="mt-4">
            <v-toolbar-title>
              <span v-if="normalMode == true">NORMAL MODE 🙊</span>
              <span v-else>PRO MODE 🙈</span>
              <span class="pl-5">
                <v-btn v-if="normalMode == true" v-on:click="normalMode = !normalMode">Bring me to Pro Mode 🕺</v-btn>
                <v-btn v-else v-on:click="normalMode = !normalMode">Bring me to Normal Mode 🧟</v-btn>
              </span>

              <span class="pl-2" v-if="normalMode == true">
                <v-btn v-on:click="renderHtml = !renderHtml">{{ renderHtml ? "Render Question Text" : "Render Display Text" }}</v-btn>
              </span>
            </v-toolbar-title>
          </v-app-bar>

          <p class="small" v-if="!normalMode">Note: Use the form below to edit the configuration manually.</p>
          <vue-json-editor v-if="!normalMode"
            v-model="project"
            :show-btns="false"
            :expndedOnStart="false"
          ></vue-json-editor>

          <!-- Questionarrie -->
          <v-expansion-panels focusable v-model="panelIndex" v-if="normalMode">
            <v-expansion-panel v-for="(question, idx) in project.questions" :key="'q_'+idx">
              <v-expansion-panel-header>
                <v-layout column :style="'color: ' + (question.shown ? 'green' : 'red')">
                  <div>
                    <span v-if="renderHtml" v-html="question.display_text" />
                    <span v-else >{{ question.text }}</span>
                  </div>
                  
                  <div v-if="question.shown"><small>Tab: {{ question.category }}</small></div>
                  
                  <div v-if="question.countFrom.question">
                    <small>Base: 
                      <span v-if="renderHtml" v-html="question.countFrom.question" />
                      <span v-else>{{ question.countFrom.question }}</span>
                    </small>
                  </div>
                </v-layout>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="12" md="12" lg="12">
                    <v-text-field
                      label="Display Text"
                      @change.native="project.questions[idx].display_text = $event.target.value"
                      :value="project.questions[idx].display_text"
                      persistent-hint
                      hint="This is how your graph title would look like"
                    />
                  </v-col>
                </v-row>

                <v-divider />
                
                <v-row>
                  <v-col cols="12" md="6" lg="6">
                    <v-text-field
                      label="Tab Name"
                      :value="project.questions[idx].category"
                      @change.native="project.questions[idx].category = $event.target.value"
                      persistent-hint
                      hint="Graph with the same tab name will be shown in the same tab"
                    />
                  </v-col>
                </v-row>

                <v-divider />
                
                <v-row>
                  <v-col cols="12" md="6" lg="6">
                    <v-checkbox
                      label="Is Filter"
                      v-model="project.questions[idx].filter"
                      persistent-hint
                      @change="() => onClickFilter(project.questions[idx])"
                      hint="Is this question a filter?"
                    />
                  </v-col>

                  <v-col cols="12" md="6" lg="6">
                    <v-text-field
                      label="Filter Key"
                      :disabled="!project.questions[idx].filter"
                      @change.native="project.questions[idx].filter_key = $event.target.value"
                      persistent-hint
                      :value="project.questions[idx].filter_key"
                      hint="It should be as short as possible! eg: mhi, hhi, etc"
                    />
                  </v-col>

                  <v-col cols="12" md="6" lg="6">
                    <v-checkbox
                      label="Is Demographic"
                      v-model="project.questions[idx].demographic"
                      persistent-hint
                      hint="Is this question a demographic question? Check this if isFilter is checked"
                    />
                  </v-col>

                  <v-col cols="12" md="6" lg="6">
                    <v-checkbox
                      label="Match Response Option Fallback"
                      v-model="project.questions[idx].matchOptionUsingSurveyToolOptionId"
                      :disabled="!(question.options && question.options.length > 0)"
                      persistent-hint
                      hint="Match response option using survey tool option ID. Use this only if decoded value/text is messed up!"
                    />
                  </v-col>
                </v-row>

                <v-divider />
                
                <v-row>
                  <v-col cols="12" md="6" lg="6">
                    <v-checkbox
                      label="Show Chart"
                      v-model="project.questions[idx].shown"
                      persistent-hint
                      hint="Should this chart be displayed?"
                    />
                  </v-col>

                  <v-col cols="12" md="6" lg="6">
                    <v-select
                      label="Chart Type"
                      :items="charts"
                      item-value="_id"
                      v-model="project.questions[idx].chart"
                      :disabled="!project.questions[idx].shown"
                      persistent-hint
                      hint="What chart type you would want your graph to render as? HorizontalStackedBar works for Radio question. StackedBar works for Rank questions. HeatMap works for Rank, Table Radio, Table Checkbox questions. Use treemap if require grouping with drill down. Heatmap works only if the base for all category are the same."
                    />
                  </v-col>

                  <v-col cols="12" md="6" lg="6">
                    <v-select
                      label="Chart Width"
                      :items="chartSize"
                      item-value="_id"
                      v-model="project.questions[idx].width"
                      :disabled="!project.questions[idx].shown"
                      persistent-hint
                      hint="What chart width you would want your chart to render as?"
                    />
                  </v-col>

                  <v-col cols="12" md="6" lg="6">
                    <v-select
                      label="Chart Height"
                      :items="chartHeight"
                      item-value="_id"
                      v-model="project.questions[idx].chartHeight"
                      :disabled="!project.questions[idx].shown"
                      persistent-hint
                      hint="What chart height you would want your chart to render as? Leave empty for default"
                    />
                  </v-col>

                  <v-col cols="12" md="6" lg="6">
                    <v-text-field
                      label="Question Type"
                      :disabled="true"
                      v-model="project.questions[idx].type"
                      persistent-hint
                      hint="Question Type in Survey Platform"
                    />
                  </v-col>

                  <v-col cols="12" md="6" lg="6">
                    <v-checkbox
                      label="Sort options based on legend order"
                      v-model="project.questions[idx].sortByLegend"
                      :disabled="!(question.options && question.options.length > 0)"
                      persistent-hint
                      hint="Select this to sort based on legend instead of max to min count. Use mainly for rating question."
                    />
                  </v-col>

                  <v-col cols="12" md="6" lg="6">
                    <v-checkbox
                      label="Show X Axis Label's"
                      :disabled="!project.questions[idx].shown"
                      v-model="project.questions[idx].showXAxisLabels"
                      persistent-hint
                      hint="Select this to show labels in a stacked bar chart"
                    />
                  </v-col>

                  <v-col cols="12" md="6" lg="6">
                    <v-checkbox
                      label="Show Statistical Data"
                      :disabled="project.questions[idx].chart != 'WORDCLOUD'"
                      v-model="project.questions[idx].showStatisticalData"
                      persistent-hint
                      hint="Wordcloud ONLY: Show mean, min, max and stdDev for free response number input"
                    />
                  </v-col>

                  <v-col cols="12" md="6" lg="6">
                    <v-text-field
                      label="Show Top N"
                      v-model="project.questions[idx].limit"
                      :hint="project.questions[idx].type == 'RANK' ? 'Show the Top N rank only' : 'Show only the Top N based on total count of selected options'"
                      type="number"
                      min="0"
                      step="1"
                    />
                  </v-col>
                </v-row>

                <v-divider />
                
                <v-row>
                  <v-col cols="12" md="6" lg="6">
                    <v-autocomplete
                      clearable
                      label="Get base from question"
                      item-text="text"
                      item-value="text"
                      :items="project.questions"
                      @click:clear="() => {
                        project.questions[idx].countFrom.question = undefined
                        project.questions[idx].countFrom.options = []
                        project.questions[idx].countFrom.filter = []
                      }"
                      @change="value => {
                        project.questions[idx].countFrom.question = value
                        project.questions[idx].countFrom.filter = []
                      }"
                      :value="project.questions[idx].countFrom.question"
                      persistent-hint
                      hint="[This will NOT work if the base question is a multiple choice!] If this question is not answered by everyone, use different base from the selected question."
                    />
                  </v-col>

                  <v-col cols="12" md="6" lg="6">
                    <v-autocomplete
                      label="Count base from question's option"
                      item-text="text"
                      item-value="text"
                      :items="findQuestionOptions(project.questions[idx].countFrom.question)"
                      @change="value => project.questions[idx].countFrom.filter = value"
                      :value="project.questions[idx].countFrom.filter"
                      :disabled="!project.questions[idx].countFrom.question"
                      persistent-hint
                      hint="[This will NOT work if the base question is a multiple choice!] Select option(s) which the base question count should based on."
                      multiple
                    />
                  </v-col>
                </v-row>

                <div class="table-responsive pt-4" v-if="question.options && question.options.length > 0">
                  <b>Question Options Configuration</b>

                  <table class="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th>Option ID</th>
                        <th>Display Option Text</th>
                        <th>Decoded Value / Text</th>
                        <th>Display Text (For Dashboard)</th>
                        <th>Parent (For TreeMap)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(option, idx) of question.options" :key="'option_'+idx">
                        <td contenteditable="true" @blur="(callback) => updateOptionData(option, 'survey_tool_option_id', callback.target.textContent)">{{ question.options[idx].survey_tool_option_id }}</td>
                        <td contenteditable="true" @blur="(callback) => updateOptionData(option, 'text', callback.target.textContent)">{{ question.options[idx].text }}</td>
                        <td contenteditable="true" @blur="(callback) => updateOptionData(option, 'decodedValue', callback.target.textContent)">{{ question.options[idx].decodedValue }}</td>
                        <td contenteditable="true" @blur="(callback) => updateOptionData(option, 'display_text', callback.target.textContent)">{{ question.options[idx].display_text }}</td>
                        <td contenteditable="true" @blur="(callback) => updateOptionData(option, 'parent', callback.target.textContent)">{{ question.options[idx].parent }}</td>
                      </tr>
                    </tbody>

                    <v-btn class="my-2 mr-2" @click="question.options.push({})" color="primary">Add New Row</v-btn>
                    <v-btn class="my-2" @click="getResponseCount(project.survey_id, question.survey_tool_question_id)" color="primary">Get SG Question Response & Count</v-btn>
                  </table>
                </div>

                <v-divider />

                <div class="table-responsive py-2" v-if="['STACKEDBAR', 'HEATMAP'].includes(question.chart)">
                  <b>Group Label Configuration: For Stacked Bar and Heat Map only!</b>

                  <table class="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th>Label Text</th>
                        <th>Display Text</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(row, idx) of question.groupLabels" :key="'option_'+idx">
                        <td contenteditable="true" @blur="(callback) => updateOptionData(row, 'text', callback.target.textContent)">{{ question.groupLabels[idx].text }}</td>
                        <td contenteditable="true" @blur="(callback) => updateOptionData(row, 'displayText', callback.target.textContent)">{{ question.groupLabels[idx].displayText }}</td>
                      </tr>
                    </tbody>

                    <v-btn class="mt-2" @click="question.groupLabels.push({})" color="primary">Add New Row</v-btn>
                  </table>
                </div>

              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel @click="!loadedSurvey && getSurvey()">
        <v-expansion-panel-header>Overall Progress</v-expansion-panel-header>
        <v-expansion-panel-content v-if="loadedSurvey">
          <v-btn x-small class="primary" @click="getSurvey()">Refresh</v-btn>
          <v-simple-table>
            <thead>
              <tr>
                <th>Overall Quota</th>
                <th>Progress</th>
                <th>Complete</th>
                <th>Partial</th>
                <th>Disqualified</th>
                <th>Deleted</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ survey.overall_quota || 0 }}</td>
                <td>{{ `${ parseInt((survey.statistics.Complete / survey.overall_quota) * 100) || 0 }%` }}</td>
                <td>{{ survey.statistics.Complete }}</td>
                <td>{{ survey.statistics.Partial }}</td>
                <td>{{ survey.statistics.Disqualified }}</td>
                <td>{{ survey.statistics.Deleted }}</td>
              </tr>
            </tbody>        
          </v-simple-table>  
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel @click="!loadedQuotas && getQuotas()">
        <v-expansion-panel-header>Quotas</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-btn x-small class="primary" @click="getQuotas()">Refresh</v-btn>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Progress %</th>
                  <th>Responses</th>
                  <th>Limit</th>
                  <th>Distributed</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, item_i) in quotas" :key="`row_${item_i}`">
                  <td>{{ item.name }}</td>
                  <td>{{ parseInt((item.responses / item.limit) * 100) }}%</td>
                  <td>{{ item.responses }}</td>
                  <td>{{ item.limit }}</td>
                  <td>{{ item.distributed }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel @click="!loadedReports && getReports()">
        <v-expansion-panel-header>Reports</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-btn x-small class="primary" @click="getReports()">Refresh</v-btn>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>Report Name</th>
                  <th>Data Progress</th>
                  <th>Data Ready</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, item_i) in reports" :key="`row_${item_i}`">
                  <td>{{ item.report_name }}</td>
                  <td>{{ item.data_progress }}%</td>
                  <td>{{ item.data_ready }}</td>
                  <td>{{ item.status }}</td>
                  <td><v-btn :href="item.public_url" target="_blank" class="primary" small>View</v-btn></td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-expansion-panel-content>
      </v-expansion-panel>

    </v-expansion-panels>

    <!-- Virtual Question Button -->
    <v-btn @click="goToVirtual" class="my-4" color="primary">Create Virtual Questions</v-btn>
    <v-btn @click="listProjectFiles()" class="ml-2" color="primary">Upload Files</v-btn>

    <v-btn @click="updateProject" x-large color="primary" fab fixed right bottom><v-icon>save</v-icon></v-btn>

    <v-dialog v-model="fileUploadDialog">
      <v-card>
        <v-card-title class="headline">
          Project Files
        </v-card-title>

        <v-card-text>
          Upload the files that you would like to share with clients on the dashboard (eg: questionarrie, raw data, processed data, etc)

          <v-form ref="uploadForm">
            <v-file-input 
              v-model="file"
              label="Upload A File"
              :rules="[(v) => !!v || 'File is required']"
            ></v-file-input>
          </v-form>
          <v-btn color="primary" @click="onUpload">Upload</v-btn>

          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>File Name</th>
                  <th>File Size</th>
                  <th class="text-center">Uploaded At</th>
                  <th class="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, row_i) in projectFiles" :key="`row_${row_i}`">
                  <td>{{ row.fileName }}</td>
                  <td>{{ row.fileSize }} KB</td>
                  <td class="text-center">{{ row.uploadedAt }}</td>
                  <td class="text-center">
                    <v-btn :href="row.filePath" download class="primary" small>Download</v-btn>
                    <v-btn v-on:click="onDelete(row)" class="error ml-2" small>Delete</v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="fileUploadDialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- SG Count -->
    <v-dialog v-model="SGQuestionOptionDialog" max-width="900">
      <v-card>
        <v-card-title class="text-h5">
          SurveyGizmo Question Option & Count
        </v-card-title>

        <v-card-text>
          <p>Configuration below are from SurveyGizmo. If the SurveyGizmo options does not match the InsightzClub question option configuration table, you will need to update the changes from SurveyGizmo to InsightzClub by manually or resync the project.</p>

          <div>Response Count:<b> {{ SGQuestionOption.totalResponses }}</b></div>
          <div>SG Question ID:<b> {{ SGQuestionOption.questionId }}</b></div>
          <div>SG Question Type:<b> {{ SGQuestionOption.type }}</b></div>

          <v-simple-table v-if="SGQuestionOption && SGQuestionOption.data && SGQuestionOption.data.length > 0">
            <template v-slot:default>
              <thead>
                <tr>
                  <th>Option ID</th>
                  <th>Option Text</th>
                  <th>Decoded Value / Text</th>
                  <th>Percentage</th>
                  <th>Count</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, item_i) in SGQuestionOption.data" :key="`row_${item_i}`">
                  <td>{{ item.id }}</td>
                  <td>{{ item.title && item.title.English ? item.title.English : item.title }}</td>
                  <td>{{ item.value }}</td>
                  <td>{{ item.percentage }}%</td>
                  <td>{{ item.count }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <div v-else><br>No data provided from SG</div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="primary" @click="SGQuestionOptionDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import vueJsonEditor from "vue-json-editor";
import RestResource from "@/services/dataServiceBuyer.js";

const service = new RestResource();

export default {
  components: {
    vueJsonEditor
  },
  data() {
    return {
      renderHtml: false,
      normalMode: true,
      filters: [],
      filter: {},
      refQues: false,
      panelIndex: null,
      project: {},
      organizationData: [],
      charts: ["BAR", "HALFDONUT", "HORIZONTALSTACKEDBAR", "HEATMAP", "PIE", "STACKEDBAR", "TREEMAP", "WORDCLOUD",],
      chartSize: ["large", "standard"],
      chartHeight: ["large", "medium", "standard"],
      tools: { "SG": "SurveyGizmo" },
      keys: ["decodedValue", "text"],
      label: [
        { _id: "P", name: "Percentage" },
        { _id: "A", name: "Absolute" },
        { _id: "PT", name: "Percentage and Number" }
      ],
      panel: 0,
      virtualQuestion: {},
      reference: {},
      organizationName: null,

      // File upload
      fileUploadDialog: false,
      file: null,
      projectFiles: null,

      // Reports
      reports: [],
      loadedReports: false,

      // Quotas
      quotas: [],
      loadedQuotas: false,

      // SG SurveyObject
      survey: {},
      loadedSurvey: false,

      statusMapping: {
        L: "Live",
        I: "Not Started",
        C: "Closed"
      },

      // SG QuestionCount
      SGQuestionOptionDialog: false,
      SGQuestionOption: {}
    };
  },

  mounted() {
    this.loadProject();
  },

  methods: {
    findQuestionOptions(questionText) {
      return questionText ? this.project.questions.find(q => q.text == questionText).options : []
    },

    updateOptionData(model, key, value) {
      model[key] = value;
    },

    loadProject() {
      this.$setLoader();
      service.fetchProject({ projectId: this.$route.params.projectId }).then(r => {
        this.project = r.data;
        this.$disableLoader();
      });
    },

    updateProjectContent(key, value) {
      this.project[key] = value;
    },

    goToVirtual() {
      window.location.href = `../VirtualQues/${this.project._id}`;
    },

    updateProject() {
      if (confirm("Update Project?")) {
        this.$setLoader()

        this.project.updated_at = new Date()
        this.project.updated_by = this.$store.state.userProfile.name
  
        service.updateProject(this.project).then(() => {
          this.loadProject();
          this.$disableLoader();
          alert("Successfully Updated Project");
        });
      }
    },

    listProjectFiles() {
      if (this.projectFiles == undefined || this.projectFiles == null) {
        this.$setLoader()

        service.getProjectFiles({ projectId: this.project._id }).then(res => {
          this.projectFiles = res.data
          this.$disableLoader()
        })
      }

      this.fileUploadDialog = true
    },

    async onUpload() {
      if (await this.$refs.uploadForm.validate()) {
        let form = new FormData();
  
        form.append("file", this.file ? this.file : "No file");
        form.append("projectFiles", JSON.stringify(this.projectFiles ? this.projectFiles : []));
        form.append("projectId", this.project._id);
        
        this.$setLoader()

        service.uploadFile(form).then(res => {
          this.file = null
          this.projectFiles = res.data
          this.$disableLoader()
        });
      }
    },

    onDelete(row) {
      this.$setLoader()

      service.deleteFile({ ...row, projectId: this.project._id }).then(res => {
        this.projectFiles = res.data
        this.$disableLoader()
      })
    },

    getReports() {
      this.$setLoader()

      service.surveyGizmoProxyPass({ type: "SURVEY_REPORT", surveyId: this.project.survey_id }).then(res => {
        this.loadedReports = true
        this.reports = res.data.data

        this.$disableLoader()
      })
    },

    getQuotas() {
      this.$setLoader()

      service.surveyGizmoProxyPass({ type: "SURVEY_QUOTA", surveyId: this.project.survey_id }).then(res => {
        this.loadedQuotas = true
        this.quotas = res.data.quotas

        this.$disableLoader()
      })
    },

    getSurvey() {
      this.$setLoader()

      service.surveyGizmoProxyPass({ type: "SURVEY", surveyId: this.project.survey_id }).then(res => {
        this.loadedSurvey = true
        this.survey = res.data.data

        this.$disableLoader()
      })
    },

    getResponseCount(surveyId, questionId) {
      this.$setLoader()

      service.surveyGizmoProxyPass({ type: "SURVEY_QUESTION_COUNT", surveyId, questionId }).then(questionCount => {
        service.surveyGizmoProxyPass({ type: "SURVEY_OPTION", surveyId, questionId }).then(questionOption => {
          this.SGQuestionOption = questionOption.data
          this.SGQuestionOption.questionId = questionId

          if (questionCount.data && questionCount.data.data && questionCount.data.data.breakdown) {
            this.SGQuestionOption.totalResponses = questionCount.data.data["total responses"]
            this.SGQuestionOption.type = questionCount.data.data.type

            for (let option of this.SGQuestionOption.data) {
              let found = questionCount.data.data.breakdown.find(c => { return c.label == option.value })
  
              if (found) {
                option.count = found.count
                option.percentage = found.percentage
              }
            }
          }

          this.SGQuestionOptionDialog = true
          this.$disableLoader()
        })
      })
    }
  }
};
</script>

<style scoped lang="scss">
@import "~bootstrap/scss/bootstrap.scss";

.bold {
  font-weight: bold;
}
</style>
